<template>
	<a-card class="opens-clicks-main-chart">
		<eChartLine :option="chartOption" />
	</a-card>
</template>

<script>
import eChartLine from "@/components/charts/eChartLine";

export default {
	props: ["broadcast"],
	components: {
		eChartLine,
	},
	computed: {
		chartOption() {
			let option = {
				legend: {
					data: ["Delivered", "Opens", "Clicks"],
				},
				title: {
					text: "Delivered, Opens & Clicks",
					left: "1%",
				},
				tooltip: {
					trigger: "axis",
				},
				grid: {
					left: "5%",
					right: "5%",
					bottom: "5%",
				},
				xAxis: {
					data: [],
				},
				yAxis: {
					splitLine: {
						show: true,
						lineStyle: {
							type: "dashed",
						},
					},
				},
				series: [],
			};
			let deliveries = {
				name: "Delivered",
				type: "line",
				smooth: false,
				itemStyle: {
					color: "#1EC48C",
				},
				data: [],
			};
			let opens = {
				name: "Opens",
				type: "line",
				smooth: false,
				itemStyle: {
					color: "#FD8C35",
				},
				data: [],
			};
			let clicks = {
				name: "Clicks",
				type: "line",
				smooth: false,
				itemStyle: {
					color: "#9693E8",
				},
				data: [],
			};

			let data = this.broadcast.openClicks || [];
			let grouped = this.sortByDate(data);

			option.xAxis.data = Object.keys(grouped);
			Object.values(grouped).forEach(({ delivered, opened, clicked }) => {
				deliveries.data.push(delivered);
				opens.data.push(opened);
				clicks.data.push(clicked);
			});
			option.series = [deliveries, opens, clicks];

			return option;
		}
	},

	methods: {
		sortByDate(data) {
			// take in array of dates and group them by the date and hour
			// and return an array of objects with the date and the count

			let grouped = {};
			data.forEach((d) => {
				let dateObj = new Date(d.createdAt);
				let dateStr = dateObj.toLocaleDateString();
				let hour = dateObj.getHours();

				dateStr += " " + `${hour}:00`;

				if (!grouped[dateStr])
					grouped[dateStr] = { delivered: 0, opened: 0, clicked: 0 };

				if (d.type === "delivered") {
					grouped[dateStr].delivered++;
				}
				if (d.type === "clicked") {
					grouped[dateStr].clicked++;
				}
				if (d.type === "opened") {
					grouped[dateStr].opened++;
				}
			});
			return grouped;
		}
	}
};
</script>

<style>
</style>
