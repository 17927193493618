<template>
    <a-card>
        <a-row :gutter="32" v-if="broadcast.sender" justify="space-between">
            <a-col :span="4">
                <ul style="list-style: none; margin: 0; padding: 0">
                    <li>
                        <h6>Subject</h6>
                        <p style="max-width: 90%">{{ broadcast.subject }}</p>
                    </li>
                    <li>
                        <h6>From</h6>
                        <div>
                            <div>
                                {{ broadcast.sender.name }}
                            </div>
                            <div>
                                {{ broadcast.sender.email }}
                            </div>
                        </div>
                    </li>
                </ul>
            </a-col>
            <a-col :span="20">
                <h3>Sending Status</h3>
                <div class="dF aC jSB mt-4">
					<a-statistic
						title="Total Leads"
						:value="broadcast.counts.totalRecipients"
						class="demo-class"
					/>
					<a-statistic
						:value="broadcast.counts.totalRecipients ? (broadcast.counts.totalRecipients - broadcast.counts.totalSent) : 0"
						class="demo-class"
					>
						<template slot="title">
							Total Suppressed Leads
							<a-tooltip overlayClassName="change-tooltip-color">
								<template slot="title">
									Email addresses suppressed due to prior bounces for improved delivery.
								</template>
								<a-icon type="question-circle" style="font-size: 14px; margin-left: 2px;"/>
							</a-tooltip>
						</template>
					</a-statistic>
					<a-statistic
						:value="broadcast.counts.totalSent"
						class="demo-class"
					>
						<template slot="title">
							Total Accepted Leads
							<a-tooltip overlayClassName="change-tooltip-color">
								<template slot="title">
									Verified emails that were accepted to maintain reputation.
								</template>
								<a-icon type="question-circle" style="font-size: 14px; margin-left: 2px;"/>
							</a-tooltip>
						</template>
					</a-statistic>
					<a-statistic
						title="Total delivered"
						:value="broadcast.counts.delivered"
						:value-style="{ color: '#2B93C6' }"
					/>
					<a-statistic
						title="Soft Bounce"
						:value="broadcast.counts.temporary_fail"
						:value-style="{ color: '#FD637C' }"
					/>
					<a-statistic
						title="Hard Bounce"
						:value="broadcast.counts.permanent_fail || 0"
						:value-style="{ color: '#FD637C' }"
					/>
					<a-statistic
						title="Bounce Rate"
						:value="bounceRate"
						class="demo-class text-primary"
						:value-style="{ color: '#FD637C' }"
						suffix="%"
					/>
                </div>
                <a-row
                    type="flex"
                    justify="space-between"
                    class="mt-5 col-width"
                >
                    <a-col :span="4">
                        <LargeStat
                            title="Total Opens"
                            :num="opensObj.rate"
                            :numsub="opensObj.total"
                            :numstyle="{ color: '#1EC48C' }"
                        />
                    </a-col>
                    <a-col :span="4">
                        <LargeStat
                            title="Total Unique Opens"
                            :num="uniqueOpensObj.rate"
                            :numsub="uniqueOpensObj.total"
                            :numstyle="{ color: '#1EC48C' }"
                        />
                    </a-col>
                    <a-col :span="4">
                        <LargeStat
                            title="Click Through Rate"
                            :num="ctr.rate"
                            :numsub="ctr.total"
                            :numstyle="{ color: '#2B93C6' }"
                        />
                    </a-col>
                    <a-col :span="4">
                        <LargeStat
                            title="Unique Click Through Rate"
                            :num="uniqueCtr.rate"
                            :numsub="uniqueCtr.total"
                            :numstyle="{ color: '#2B93C6' }"
                        />
                    </a-col>
                    <a-col :span="4">
                        <LargeStat
                            title="Unsubscribed"
                            :num="unsub.rate"
                            :numsub="unsub.total"
                            :numstyle="{ color: '#FD637C' }"
                        />
                    </a-col>
                </a-row>

                <div class="stat-line-grid mt-5">
                    <div class="stat-line-item">
                        <div><strong>Total Clicks</strong></div>
                        <div class="text-md">
                            {{
                                broadcast.counts.totalClicked &&
                                broadcast.counts.totalClicked.toLocaleString(
                                    "us"
                                )
                            }}
                        </div>
                    </div>
                    <div class="stat-line-item">
                        <div>Click-to-Open Rate</div>
                        <div>{{ clickOpenRate }}%</div>
                    </div>
                    <div class="stat-line-item grey">
                        <div>Last Opened</div>
                        <div>{{ toTime(broadcast.lastOpen) }}</div>
                    </div>
                    <div class="stat-line-item">
                        <div>Unique Click-to-Open Rate</div>
                        <div>{{ uniqueClickOpenRate }}%</div>
                    </div>
                    <div class="stat-line-item grey">
                        <div>Last Clicked</div>
                        <div>{{ toTime(broadcast.lastClicked) }}</div>
                    </div>
                    <div class="stat-line-item">
                        <div>Avg. Time to Open</div>
                        <div>{{ avgOpen }}</div>
                    </div>
                    <div class="stat-line-item">
                        <div>Avg. Time to Click</div>
                        <div>{{ avgClick }}</div>
                    </div>
                    <div class="stat-line-item">
                        <div>No# of Complaints</div>
                        <div>{{ broadcast.counts.complained }}</div>
                    </div>
                </div>
            </a-col>
        </a-row>
    </a-card>
</template>

<script>
import LargeStat from "@/components/reports/LargeStat.vue";

export default {
    props: ["broadcast"],
    components: {
        LargeStat,
    },
    computed: {
        avgClick() {
            // given the sentDate and list of dates that a click event took place. calculate average time it took a recipient to click
            let sentDate = parseInt(
                this.broadcast.sentDate || this.broadcast.createdAt
            );
            let eventDates = this.broadcast.openClicks
                .filter((click) => click.type === "clicked")
                .map((click) => new Date(click.createdAt).getTime());
            let totalTime = 0;
            let totalClicks = 0;
            eventDates.forEach((date) => {
                totalTime += date - sentDate;
                totalClicks++;
            });
            if (!totalClicks) return null;
            return this.toDur(totalTime / totalClicks);
        },
        avgOpen() {
            //given the sentDate and list of dates that a click event took place. remove the outliers and calculate average time it took a recipient to open
            let sentDate = parseInt(
                this.broadcast.sentDate || this.broadcast.createdAt
            );
            let eventDates = this.broadcast.openClicks
                .filter((click) => click.type === "opened")
                .map((click) => new Date(click.createdAt).getTime());
            let totalTime = 0;
            let totalOpens = 0;
            eventDates.forEach((date) => {
                totalTime += date - sentDate;
                totalOpens++;
            });
            if (!totalOpens) return null;
            return this.toDur(totalTime / totalOpens);
        },
        uniqueClickOpenRate() {
            if (!this.broadcast.counts.clicked || !this.broadcast.counts.opened)
                return 0;
            return Math.round(
                (this.broadcast.counts.clicked / this.broadcast.counts.opened) *
                    100
            );
        },
        clickOpenRate() {
            if (
                !this.broadcast.counts.totalClicked ||
                !this.broadcast.counts.totalOpened
            )
                return 0;
            return Math.round(
                (this.broadcast.counts.totalClicked /
                    this.broadcast.counts.totalOpened) *
                    100
            );
        },
        bounceRate() {
            let bounced =
                this.broadcast.counts.temporary_fail +
                this.broadcast.counts.permanent_fail;
            if (!bounced || !this.broadcast.counts.totalSent) return 0;
            return (
                (bounced / this.broadcast.counts.totalSent) *
                100
            ).toFixed(2);
        },
        opensObj() {
            let opens = this.broadcast.counts.totalOpened;
            let delivered = this.broadcast.counts.delivered;
            if (!delivered) return { total: 0, rate: "0%" };
            return {
                total: opens && opens.toLocaleString(),
                rate: ((opens / delivered) * 100).toFixed(2) + "%",
            };
        },
        uniqueOpensObj() {
            let opens = this.broadcast.counts.opened;
            let delivered = this.broadcast.counts.delivered;
            if (!delivered) return { total: 0, rate: "0%" };
            return {
                total: opens && opens.toLocaleString(),
                rate: ((opens / delivered) * 100).toFixed(2) + "%",
            };
        },
        ctr() {
            let clicks = this.broadcast.counts.totalClicked;
            let delivered = this.broadcast.counts.delivered;
            if (!delivered) return { total: 0, rate: "0%" };
            return {
                total: clicks && clicks.toLocaleString(),
                rate: ((clicks / delivered) * 100).toFixed(2) + "%",
            };
        },
        uniqueCtr() {
            let clicks = this.broadcast.counts.clicked;
            let delivered = this.broadcast.counts.delivered;
            if (!delivered) return { total: 0, rate: "0%" };
            return {
                total: clicks && clicks.toLocaleString(),
                rate: ((clicks / delivered) * 100).toFixed(2) + "%",
            };
        },
        unsub() {
            let unsub = this.broadcast.counts.unsubscribed || 0;
            let delivered = this.broadcast.counts.delivered;
            if (!delivered) return { total: 0, rate: "0%" };
            return {
                total: unsub && unsub.toLocaleString(),
                rate: ((unsub / delivered) * 100).toFixed(2) + "%",
            };
        },
    },
    methods: {
        toTime(time) {
            if (!time) return "-";
            let date = new Date(time);
            return date && date.toLocaleString();
        },
        toDur(time) {
            if (!time) return "-";
            let seconds = time / 1000;
            let minutes = Math.floor(seconds / 60);
            let hours = minutes / 60;
            let days = hours / 24;
            let weeks = days / 7;

            if (weeks >= 1)
                return `${Math.floor(weeks)}w, ${Math.floor(days % 7)}d`;
            if (days >= 1)
                return `${Math.floor(days)}d, ${Math.floor(hours % 24)}h`;
            if (hours >= 1) return `${Math.floor(hours)}h, ${minutes % 60}m`;
            return `${minutes}m, ${Math.floor(seconds % 60)}s`;
        },
    },
};
</script>

<style lang="scss">
.stat-line-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem 2.5rem;
}
.stat-line-item {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-size: 1.1rem;
    border-bottom: 1px solid #eee;
    padding-bottom: 0.5rem;
    &.grey {
        color: #bbb;
    }
}

.col-width {
    .ant-col-4 {
        width: 18%;
    }
}
</style>
