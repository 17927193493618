<template>
    <div>
        <OpensClicksLineChart :broadcast="broadcast" />

        <a-card class="mt-4" title="Most active recipient">
            <a-table
                bordered
                :columns="mostActive.columns"
                :row-key="(record) => record.contact"
                :data-source="mostActive.data"
				:pagination="{
                    current: currentPage,
                    total: (broadcast && broadcast.counts && broadcast.counts.totalRecipients || 0),
                    pageSize: pageSize,
                }"
				@change="handleChange"
                :loading="mostActive.loading"

            >
				<template #deliveredAt="deliveredAt">
					<span v-if="deliveredAt">
						{{ moment(deliveredAt).format('lll') }}
					</span>
				</template>
            </a-table>
        </a-card>

		<!-- We will have to hide the Geo location reports due to Mailgun inaccuracy, once fixed we can reactivate(Remove the v-if="false" condition). -->
        <a-card
			v-if="false"
            class="mt-4"
            :tab-list="geoTabs"
            :active-tab-key="geoTabKey"
            @tabChange="(key) => (geoTabKey = key)"
        >
            <div v-if="geoTabKey === 'opens'">
                <MapCluster :markers="openMarkers" :key="updatedTime" />

                <div class="mt-4">
                    <a-row :gutter="32">
                        <a-col :span="12">
                            <a-table
                                class="outside-border"
                                :columns="countryTable.columns.opens"
                                :row-key="(record) => record.id"
                                :data-source="openMarkers"
                                :loading="countryTable.loading"
                                :pagination="false"
                            />
                        </a-col>
                        <a-col :span="12">
                            <a-tabs
                                default-active-key="1"
                                class="tab-bordered"
                                @change="updatedTime2 = Date.now()"
                            >
                                <a-tab-pane key="1" tab="Device">
                                    <div class="p-">
                                        <eDonut
                                            :dataset="openO"
                                            :key="updatedTime2"
                                        />
                                    </div>
                                </a-tab-pane>
                                <a-tab-pane key="2" tab="Browsers">
                                    <eDonut
                                        :dataset="openB"
                                        :key="updatedTime2"
                                    />
                                </a-tab-pane>
                            </a-tabs>
                        </a-col>
                    </a-row>
                </div>
            </div>
            <div v-else-if="geoTabKey === 'clicks'">
                <MapCluster :key="updatedTime" :markers="clickMarkers" />
                <div class="mt-4">
                    <a-row :gutter="32">
                        <a-col :span="12">
                            <a-table
                                bordered
                                :columns="countryTable.columns.clicks"
                                :row-key="(record) => record.id"
                                :data-source="clickMarkers"
                                :loading="countryTable.loading"
                            />
                        </a-col>
                        <a-col :span="12">
                            <a-tabs
                                default-active-key="1"
                                class="tab-bordered"
                                @change="updatedTime2 = Date.now()"
                            >
                                <a-tab-pane key="1" tab="Device">
                                    <div class="p-">
                                        <eDonut
                                            :dataset="clickO"
                                            :key="updatedTime2"
                                        />
                                    </div>
                                </a-tab-pane>
                                <a-tab-pane key="2" tab="Browsers">
                                    <eDonut
                                        :dataset="clickB"
                                        :key="updatedTime2"
                                    />
                                </a-tab-pane>
                            </a-tabs>
                        </a-col>
                    </a-row>
                </div>
            </div>

            <div slot="tabBarExtraContent" class="dF" style="gap: 20px">
                <div class="dF" style="gap: 5px">
                    <strong class="text-md" v-text="currentTabCount" />
                </div>
                <div class="dF" style="gap: 5px">
                    <span>Download:</span>
                    <a
                        @click.prevent="getCSV"
                        href="javascript:;"
                        class="text-primary"
                        >CSV</a
                    >
                </div>
            </div>
        </a-card>
    </div>
</template>

<script>
import OpensClicksLineChart from "@/components/email/OpensClicksLineChart";
import eDonut from "@/components/charts/eDonut.vue";
import MapCluster from "@/components/charts/MapCluster.vue";
import countries from "@/assets/countries.json";
import moment from "moment";

export default {
    props: ["broadcast"],
    components: {
        OpensClicksLineChart,
        MapCluster,
        eDonut,
    },
    watch: {
        geoTabKey(val) {
            this.updatedTime = Date.now();
        },
    },
    data: () => ({
        updatedTime: Date.now(),
        updatedTime2: Date.now(),
        geoTabs: [
            {
                key: "opens",
                tab: "Opens",
            },
            {
                key: "clicks",
                tab: "Clicks",
            },
        ],
        geoTabKey: "opens",
		currentPage: 1,
        pageSize: 10,
        mostActive: {
            loading: true,
            data: [],
            columns: [
                {
                    title: "Email",
                    dataIndex: "contact",
                    width: "20%",
                },
				{
                    title: "Delivered At",
                    dataIndex: "deliveredAt",
                    key: "deliveredAt",
					scopedSlots: { customRender: "deliveredAt" },
                },
                {
                    title: "Opens",
                    dataIndex: "totalOpens",
                },
                {
                    title: "Clicks",
                    dataIndex: "totalClicks",
                },
            ],
        },
        countryTable: {
            loading: false,
            columns: {
                clicks: [
                    {
                        title: "Country",
                        dataIndex: "label",
                    },
                    {
                        title: "Clicks",
                        className: "text-right",
                        dataIndex: "value",
                    },
                ],
                opens: [
                    {
                        title: "Country",
                        dataIndex: "label",
                    },
                    {
                        title: "Opens",
                        className: "text-right",
                        dataIndex: "value",
                    },
                ],
            },
        },
    }),
    computed: {
        clickO() {
            let os =
                (this.broadcast &&
                    this.broadcast.os &&
                    this.broadcast.os["clicked"]) ||
                {};
            return Object.keys(os).map((key) => {
                return {
                    name:
                        key === "NaN" || key.toLowerCase().includes("undefined")
                            ? "Other"
                            : key,
                    value: os[key],
                };
            });
        },
        clickB() {
            let browsers =
                (this.broadcast &&
                    this.broadcast.browsers &&
                    this.broadcast.browsers["clicked"]) ||
                {};

            return Object.entries(browsers).map(([key, val]) => ({
                name:
                    key === "NaN" || key.toLowerCase().includes("undefined")
                        ? "Other"
                        : key,
                value: val,
            }));
        },
        openO() {
            let os =
                (this.broadcast &&
                    this.broadcast.os &&
                    this.broadcast.os["opened"]) ||
                {};
            return Object.keys(os).map((key) => {
                return {
                    name:
                        key === "NaN" || key.toLowerCase().includes("undefined")
                            ? "Other"
                            : key,
                    value: os[key],
                };
            });
        },
        openB() {
            let browsers =
                (this.broadcast &&
                    this.broadcast.browsers &&
                    this.broadcast.browsers["opened"]) ||
                {};

            return Object.entries(browsers).map(([key, val]) => ({
                name:
                    key === "NaN" || key.toLowerCase().includes("undefined")
                        ? "Other"
                        : key,
                value: val,
            }));
        },
        clickMarkers() {
            const locations = this.broadcast.locations || {};
            const clicked = locations.clicked || {};
            const country = clicked.country || {};
            return Object.entries(country).map(([key, value]) => {
                let ctr = countries[key];
                return {
                    label: ctr.name,
                    location: [ctr.latitude, ctr.longitude],
                    value: value,
                };
            });
        },
        openMarkers() {
            const locations = this.broadcast.locations || {};
            const opened = locations.opened || {};
            const country = opened.country || {};
            return Object.entries(country).map(([key, value]) => {
                let ctr = countries[key];
                return {
                    label: ctr.name,
                    location: [ctr.latitude, ctr.longitude],
                    value: value,
                };
            });
        },
        currentTabCount() {
            if (this.geoTabKey == "opens") {
                let total = this.openMarkers.reduce((acc, item) => {
                    return acc + item.value;
                }, 0);
                return `${total.toLocaleString("us")} Opens`;
            } else {
                let total = this.clickMarkers.reduce((acc, item) => {
                    return acc + item.value;
                }, 0);
                return `${parseInt(total).toLocaleString("us")} Clicks`;
            }
        }
    },
    methods: {
		moment,

        browsers(type = "opened") {
            let browsers =
                (this.broadcast &&
                    this.broadcast.browsers &&
                    this.broadcast.browsers[type]) ||
                {};

            return Object.entries(browsers).map(([key, val]) => ({
                name:
                    key === "NaN" || key.toLowerCase().includes("undefined")
                        ? "Other"
                        : key,
                value: val,
            }));
        },
        os(type = "opened") {
            let os =
                (this.broadcast &&
                    this.broadcast.os &&
                    this.broadcast.os[type]) ||
                {};
            return Object.keys(os).map((key) => {
                return {
                    name:
                        key === "NaN" || key.toLowerCase().includes("undefined")
                            ? "Other"
                            : key,
                    value: os[key],
                };
            });
        },
        fetchRecipientData() {
			let start = (this.currentPage - 1) * this.pageSize;
			this.mostActive.loading = true;
			this.$api
				.get(`/events/${this.$route.params.id}/mostactive?_start=${start}&_limit=${this.pageSize}`)
				.then((res) => {
					this.mostActive.loading = false;
					this.mostActive.data = res.data;
				}).catch((err) => { });
		},

		handleChange(pagination, filter, sorter) {
			if (pagination) {
				this.currentPage = pagination.current;
				this.selectedRowKeys = [];
				this.selectedRowIds = [];
			}

			this.fetchRecipientData();
		},
    },
    mounted() {
        this.fetchRecipientData();
    },
};
</script>

<style lang="scss">
.tab-bordered {
    border: 1px solid #dee2e6;
    .ant-tabs-bar.ant-tabs-top-bar {
        padding: 0 1rem;
    }
    .p- {
        padding: 0 1rem;
    }
}
.outside-border table {
    border: 1px solid #e8e8e8;
}
</style>
