<template>
	<a-card style="padding:0" class="table-card">
		<a-table :loading="loading" class="tableStyle" :columns="columns" :data-source="filtered"
			:rowKey="(r) => r.recipient + r.createdAt">
			<div slot="name" slot-scope="record" class="dF fC">
				{{ record.name }}
				<span style="color:var(--med-gray)">
					<a-tooltip overlayClassName="change-tooltip-color" :title="`Subject: ${record.subject}`">
						Subject: {{ trim(record.subject, 50) }}
					</a-tooltip>
				</span>
			</div>
			<div slot="sent" slot-scope="record" class="dF">
				{{ formatDate(record.counts && record.counts.sent || record.createdAt) }}
			</div>
			<div slot="delivered" slot-scope="record" class="dF">
				{{ record.counts && record.counts.delivered || 0 }}
			</div>
			<div slot="opened" slot-scope="record" class="dF">
				{{ record.counts && record.counts.opened || 0 }}
			</div>
			<div slot="openRate" slot-scope="record" class="dF">
				<span v-if="record.counts && record.counts.delivered && record.counts.delivered > 0">
					{{ Math.floor(record.counts.opened / record.counts.delivered * 100) }}%
				</span>
				<span v-else class="text-med-gray">
					0%
				</span>
			</div>

			<div slot="ctr" slot-scope="record" class="dF">
				<span v-if="record.counts && record.counts.clicked && record.counts.clicked > 0">
					{{ Math.floor(record.counts.clicked / record.counts.delivered * 100) }}%
				</span>
				<span v-else class="text-med-gray">
					0%
				</span>
			</div>
			<div slot="bounceRate" slot-scope="record" class="dF">
				<span v-if="record.counts && record.counts.temporary_fail && record.counts.permanent_fail">
					{{ Math.floor((record.counts.temporary_fail + record.counts.permanent_fail) / record.contacts * 100) }}%
				</span>
				<span v-else class="text-med-gray">
					0%
				</span>
			</div>

			<div slot="bounceType" slot-scope="record">
				{{ record.bounceType === 'hard' ? 'Hard Bounce' : 'Soft Bounce' }}
			</div>

			<div slot="bounceReason" slot-scope="record">
				{{ record.bounceReason }}
			</div>

			<div slot="unsubReason" slot-scope="record">
				{{ record.unsubReason }}
			</div>

			<div slot="createdAt" slot-scope="record">
				{{ moment(record.createdAt).format('lll') }}
			</div>

			<div slot="actions" slot-scope="record">
				<a-button @click="$router.push(`/email/${record.id}`)">View Report</a-button>
			</div>

		</a-table>
	</a-card>
</template>

<script>
import moment from "moment"

export default {
	props: ['type', 'pageSize'],
	data: () => ({
		currentPage: 0,
		pages: 0,
		size: 10,
		total: 0,
		loading: true,
		filtered: [],
		columns: [
			{
				title: 'Email',
				dataIndex: 'recipient',
			},
			{
				title: 'Date',
				scopedSlots: { customRender: 'createdAt' }
			},
		]
	}),
	methods: {
		moment,

		async getData() {
			try {
				let type = this.type === 'bounced' ? 'temporary_fail,permanent_fail' : this.type
				let { data: count } = await this.$api.get(`/events/${this.$route.params.id}/get-count?type=${type}&unique=1`)
				let { data } = await this.$api.get(`/events/${this.$route.params.id}/extract?types=${type}&fields=type,recipient&unique=1`)
				this.filtered = data
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			}

			this.loading = false
		}
	},
	created() {
		this.currentPage = 0
		this.size = this.pageSize || 15
		this.total = 0
		this.loading = true
		this.getData()
		if (this.type === 'failed') {
			this.columns.splice(1, 0, {
				title: 'Bounce Type',
				scopedSlots: { customRender: 'bounceType' }
			})
			this.columns.splice(2, 0, {
				title: 'Bounce Reason',
				scopedSlots: { customRender: 'bounceReason' }
			})
		}

		if (this.type === 'unsubscribed') {
			this.columns.splice(1, 0, {
				title: 'Unsubscribe Reason',
				scopedSlots: { customRender: 'unsubReason' }
			})
		}
	}
}
</script>

<style>
</style>
