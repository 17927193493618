<template>
    <v-chart class="chart" :option="option" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
//   VisualMapComponent,
  LegendComponent,
//   ToolboxComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
//   VisualMapComponent,
  LegendComponent,
//   ToolboxComponent,
]);

export default {
  components: {
    VChart
  },
  props: {
      dataset: {
          type: Array,
          required: true
      },
  },
  data() {
    return {
        
    };
  },
    computed:{
        finalDataset(){
            return this.dataset
        },
        option(){
            return   {
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                legend: {
                    left: 'center',
                    top: 'bottom',
                    data: this.finalDataset.map(x => x.name)
                },
                toolbox: {
                    show: false,
                    feature: {
                        mark: { show: true },
                        dataView: { show: true, readOnly: false },
                        restore: { show: true },
                        saveAsImage: { show: true }
                    }
                },
                series: [
                    {
                        name: 'Device',
                        type: 'pie',
                        radius: [80, 140],
                        roseType: 'radius',
                        itemStyle: {
                            borderRadius: 3
                        },
                        label: {
                            show: true
                        },
                        emphasis: {
                            label: {
                                show: true
                            }
                        },
                        data: this.finalDataset
                    }
                    
                ]
            }
        }
    },
};
</script>

<style scoped>
    .chart {
        height: 500px;
    }
</style>