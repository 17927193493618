<template>
    <div class="large-stat">
        <div class="statistic-title">{{title}}</div>
        <div class="md__dF jSB" style="align-items:baseline;">
            <div class="statistic-num" :style="numstyle||{}">{{num}}</div>
            <div class="statistic-num-sub"  :style="numstyle||{}">{{numsub}}</div>
        </div>
    </div>
</template>

<script>
export default {
    props:['title','num','numsub','numstyle'],
}
</script>

<style lang="scss" scoped>
    .large-stat{
        border:1px solid #ededed;
        padding:1rem;
        .statistic-title{
            line-height:1.2;
            font-size:1.30rem;
            margin-bottom:1rem;
            font-weight:200;
			min-height: 70px;
			overflow-wrap: break-word;
            @media screen and (max-width:1200px){
                font-size:1rem;
                margin-bottom:0.5rem;
            }
        }
        .statistic-num{
            font-size:2.1rem;
            @media screen and (max-width:1400px){
                font-size:1.5rem;
            }
        }
        .statistic-num-sub{
            font-size:1.40rem;
            @media screen and (max-width:1200px){
                font-size:1rem;
            }
        }

    }

</style>
