<template>
    <div class="map-chart">
        <LMap
            ref="map"
            @ready="doSomethingOnReady"
            :zoom="zoom"
            :center="center"
            style="height: 500px"
        >
            <LTileLayer :url="url" :attribution="attribution" />
            <LCircleMarker
                v-for="(marker, markerI) in circleData()"
                :radius="radius"
                :key="markerI"
                :lat-lng="marker.location"
                @mouseover="openPopUp(marker.location, marker)"
            >
            </LCircleMarker>
            <LLayerGroup ref="features">
                <LPopup>
                    <span>
                        <span class="mr-2 block">{{
                            selectedMarker.label
                        }}</span>
                        <span class="text-primary">{{
                            selectedMarker.value
                        }}</span>
                    </span>
                </LPopup>
            </LLayerGroup>
        </LMap>
    </div>
</template>
<script>
import {
    LMap,
    LTileLayer,
    LCircleMarker,
    LLayerGroup,
    LPopup,
} from "vue2-leaflet";
import { Icon } from "leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
    name: "MapChart",
    props: {
        id: {
            type: String,
            default: Math.random().toString(36).substr(2, 5),
        },
        markers: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        LMap,
        LTileLayer,
        LCircleMarker,
        LLayerGroup,
        LPopup,
    },
    data: () => ({
        center: [51.505, -0.159],
        zoom: 2,
        radius: 10,
        map: {},
        selectedMarker: {},
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution: "",
    }),
    methods: {
        doSomethingOnReady(map) {
            map.scrollWheelZoom.disable();
            this.map = map;
        },
        openPopUp(latLng, marker) {
            this.selectedMarker = marker;
            this.$refs.features.mapObject.openPopup(latLng);
        },
        circleData() {
            return this.markers
                .sort((a, b) => {
                    return b.value - a.value;
                })
                .map((marker) => {
                    return {
                        ...marker,
                        html: `<b>${marker.label}</b><br/>${marker.value} clicks`,
                    };
                });
        },
    },
};
</script>
<style scoped>
</style>
