<template>
    <div>
        <div class="dF aC" >
            <a v-if="back" class="mr-4" href="#" @click.prevent="goBack">
                <a-icon type="arrow-left" />
            </a>
            <div>
                <h4 class="mb-0">{{title}}</h4>
                <small>{{sub}}</small>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:['title','sub','back'],
        methods:{
            goBack(){
                this.$router.push(this.back);
            }
        },
    }
</script>

<style>

</style>